.mapMenu {
  position: fixed;
  padding: 15px;
  bottom: 0;
  left: 0;
  display: flex;
  background: #000;
  width: 100%;
  z-index: 100;
}

@media (min-width: 768px) {
    .mapMenu {
        background: none;
        width: auto;
    }
}

.projectNumber {
  color: #fff;
  font-size: 12px;
  margin-right: 60px;
}

.openGallery {
  background: none;
  color: #fff;
  border: 1px solid #fff;
  padding: 2px 10px;
  cursor: pointer;
  outline: none;
  transition: opacity 200ms;
}

.openGalleryActive {
    composes: openGallery;
    background: #444;
    opacity: 0.7;
}
