.about {
  flex-basis: 60%;
  margin-left: 90px;
  text-align: left;
}

@media (min-width: 768px) {
  .about {
    flex-basis: 50%;
    margin-left: 10%;
  }
}
