.menu {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  z-index: 10;
}

.hamburgerSpring {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none;
}
.hamburgerSpring:hover {
  opacity: 0.7;
}

.hamburgerSpringActive {
  composes: hamburgerSpring;
}

.hamburgerSpringActive:hover {
  opacity: 0.7;
}

.hamburgerSpringActive .hamburgerInner,
.hamburgerSpringActive .hamburgerInner::before,
.hamburgerSpringActive .hamburgerInner::after {
  background-color: #fff;
}

.hamburgerBox {
  width: 32px;
  height: 20px;
  display: inline-block;
  position: relative;
}

.hamburgerInner {
  display: block;
  top: 50%;
  margin-top: -2px;
  top: 2px;
  transition: background-color 0s 0.13s linear;
}
.hamburgerInner,
.hamburgerInner::before,
.hamburgerInner::after {
  width: 32px;
  height: 2px;
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburgerInner::before,
.hamburgerInner::after {
  content: '';
  display: block;
}
.hamburgerInner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburgerInner::after {
  bottom: -10px;
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburgerSpringActive .hamburgerInner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}
.hamburgerSpringActive .hamburgerInner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburgerSpringActive .hamburgerInner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.menuList {
  transform: translateX(-100%);
  transition: all 300ms;
  color: #fff;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  opacity: 0;
}

.menuListActive {
  composes: menuList;
  transform: translateX(0);
  opacity: 1;
}

.menuItem {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  padding-left: 15px;
}

.menuItem:hover {
  opacity: 0.7;
}

.menuListItem {
  margin-bottom: 1em;
}