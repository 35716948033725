@import url('https://fonts.googleapis.com/css?family=Poppins|Space+Mono&display=swap');

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
  font-family: 'Space Mono', monospace;
  background: #000;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header h1 {
  color: #fff;
  position: absolute;
  top: 10px;
  right: 15px;
  margin: 0;
  text-align: right;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  line-height: 1em
}

@media (min-width: 768px) {
  .header h1 {
    width: 1ch;
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 5.75vh;
    line-height: 6vh;
    letter-spacing: 0;
    text-align: center;
    top: 15px;
    left: auto;
  }
  @supports (text-orientation: upright) {
    .header h1 {
      writing-mode: vertical-rl;
      text-orientation: upright;
    }
  }
}

@media (min-width: 960px) {
  .header h1 {
    font-size: 5.8vh;
  }
}

h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  text-transform: uppercase;
  /* letter-spacing: 3px; */
}

.content {
  position: absolute;
  top: 20vh;
  left: 0;
  width: 100%;
  color: #fff; 
  display: flex;
  padding-bottom: 100px;
}

@media(min-width: 768px) {
  .content {
    overflow: hidden;
    padding-bottom: 50px;
  }
}

a {
  color: #fff;
  text-decoration: none;
}
