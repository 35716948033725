.p5Canvas {
  width: 100vw;
  height: 100vh;
  display: block;
}

.map {
  cursor: pointer;
  max-height: 100vh;
  overflow: hidden;
}
