.gallery {
    position: fixed;
    bottom: 45px;
    left: 15px;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
}

.galleryClosed {
    composes: gallery;
    display: none;
}

.galleryImg {
    object-fit: contain;
    object-position: 0% 100%;
    width: 100%;
    height: auto;
    max-height: 80vh;
    max-width: 80vw;
}

.caption {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #FFF;
    text-align: left;
}