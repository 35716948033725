@import url(https://fonts.googleapis.com/css?family=Poppins|Space+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
  font-family: 'Space Mono', monospace;
  background: #000;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header h1 {
  color: #fff;
  position: absolute;
  top: 10px;
  right: 15px;
  margin: 0;
  text-align: right;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  line-height: 1em
}

@media (min-width: 768px) {
  .header h1 {
    width: 1ch;
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 5.75vh;
    line-height: 6vh;
    letter-spacing: 0;
    text-align: center;
    top: 15px;
    left: auto;
  }
  @supports (text-orientation: upright) {
    .header h1 {
      -webkit-writing-mode: vertical-rl;
              writing-mode: vertical-rl;
      text-orientation: upright;
    }
  }
}

@media (min-width: 960px) {
  .header h1 {
    font-size: 5.8vh;
  }
}

h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  text-transform: uppercase;
  /* letter-spacing: 3px; */
}

.content {
  position: absolute;
  top: 20vh;
  left: 0;
  width: 100%;
  color: #fff; 
  display: flex;
  padding-bottom: 100px;
}

@media(min-width: 768px) {
  .content {
    overflow: hidden;
    padding-bottom: 50px;
  }
}

a {
  color: #fff;
  text-decoration: none;
}

.About_about__2v-0B {
  flex-basis: 60%;
  margin-left: 90px;
  text-align: left;
}

@media (min-width: 768px) {
  .About_about__2v-0B {
    flex-basis: 50%;
    margin-left: 10%;
  }
}

.Index_index__1z6JX {
  flex-basis: 60%;
  margin-left: 90px;
  text-align: left;
}

@media (min-width: 768px) {
  .Index_index__1z6JX {
    flex-basis: 50%;
    margin-left: 10%;
  }
}

.Contact_contact__-SOsb {
  flex-basis: 60%;
  margin-left: 90px;
  text-align: left;
}
@media (min-width: 768px) {
  .Contact_contact__-SOsb {
    flex-basis: 50%;
    margin-left: 10%;
  }
}

.Detail_detail__l98mH {
  flex-basis: 60%;
  margin-left: 90px;
  text-align: left;
}

@media (min-width: 768px) {
  .Detail_detail__l98mH {
    flex-basis: 50%;
    margin-left: 10%;
  }
}

.Menu_menu__nN2sZ {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  z-index: 10;
}

.Menu_hamburgerSpring__3uz6N {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none;
}
.Menu_hamburgerSpring__3uz6N:hover {
  opacity: 0.7;
}

.Menu_hamburgerSpringActive__1CeoA {
}

.Menu_hamburgerSpringActive__1CeoA:hover {
  opacity: 0.7;
}

.Menu_hamburgerSpringActive__1CeoA .Menu_hamburgerInner__P0LDJ,
.Menu_hamburgerSpringActive__1CeoA .Menu_hamburgerInner__P0LDJ::before,
.Menu_hamburgerSpringActive__1CeoA .Menu_hamburgerInner__P0LDJ::after {
  background-color: #fff;
}

.Menu_hamburgerBox__1P24f {
  width: 32px;
  height: 20px;
  display: inline-block;
  position: relative;
}

.Menu_hamburgerInner__P0LDJ {
  display: block;
  top: 50%;
  margin-top: -2px;
  top: 2px;
  transition: background-color 0s 0.13s linear;
}
.Menu_hamburgerInner__P0LDJ,
.Menu_hamburgerInner__P0LDJ::before,
.Menu_hamburgerInner__P0LDJ::after {
  width: 32px;
  height: 2px;
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.Menu_hamburgerInner__P0LDJ::before,
.Menu_hamburgerInner__P0LDJ::after {
  content: '';
  display: block;
}
.Menu_hamburgerInner__P0LDJ::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.Menu_hamburgerInner__P0LDJ::after {
  bottom: -10px;
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.Menu_hamburgerSpringActive__1CeoA .Menu_hamburgerInner__P0LDJ {
  transition-delay: 0.22s;
  background-color: transparent !important;
}
.Menu_hamburgerSpringActive__1CeoA .Menu_hamburgerInner__P0LDJ::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg);
}
.Menu_hamburgerSpringActive__1CeoA .Menu_hamburgerInner__P0LDJ::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
          transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.Menu_menuList__20o4P {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: all 300ms;
  color: #fff;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  opacity: 0;
}

.Menu_menuListActive__wQnSE {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}

.Menu_menuItem__1JvTH {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  padding-left: 15px;
}

.Menu_menuItem__1JvTH:hover {
  opacity: 0.7;
}

.Menu_menuListItem__1ryBu {
  margin-bottom: 1em;
}
.Map_p5Canvas__6-n-l {
  width: 100vw;
  height: 100vh;
  display: block;
}

.Map_map__2S7Si {
  cursor: pointer;
  max-height: 100vh;
  overflow: hidden;
}

.Gallery_gallery__H74xe {
    position: fixed;
    bottom: 45px;
    left: 15px;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
}

.Gallery_galleryClosed__1sG_m {
    display: none;
}

.Gallery_galleryImg__2aTq0 {
    object-fit: contain;
    object-position: 0% 100%;
    width: 100%;
    height: auto;
    max-height: 80vh;
    max-width: 80vw;
}

.Gallery_caption__3lbNF {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #FFF;
    text-align: left;
}
.MapMenu_mapMenu__1CrZ_ {
  position: fixed;
  padding: 15px;
  bottom: 0;
  left: 0;
  display: flex;
  background: #000;
  width: 100%;
  z-index: 100;
}

@media (min-width: 768px) {
    .MapMenu_mapMenu__1CrZ_ {
        background: none;
        width: auto;
    }
}

.MapMenu_projectNumber__bctps {
  color: #fff;
  font-size: 12px;
  margin-right: 60px;
}

.MapMenu_openGallery__14lhb {
  background: none;
  color: #fff;
  border: 1px solid #fff;
  padding: 2px 10px;
  cursor: pointer;
  outline: none;
  transition: opacity 200ms;
}

.MapMenu_openGalleryActive__2b-c6 {
    background: #444;
    opacity: 0.7;
}

